import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./BookingButton.styles";

const Button = loadable(() => import("@material-ui/core/Button"));

const BookingButton = () => {
	const setUi = useContext(UiContext)?.[1];
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const handleClick = () => {
		setUi(prevState => ({ ...prevState, overlayOpen: "booking" }));
	};

	return (
		<div className={clsx(css.bookingButton, "mco-view-component-header-booking-button")}>
			<Button
				className={clsx(css.button, "mco-view-component-header-booking-button__button")}
				color="primary"
				size={isMobile ? "medium" : "large"}
				variant="contained"
				onClick={handleClick}
			>
				<Local identifier="quickSearch.buttons.search" l={l} />
			</Button>
		</div>
	);
};

export default BookingButton;
