import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	infrastructure: {
		marginBottom: theme.spacing(7) + "px !important",
		marginTop: theme.spacing(7) + "px !important",
		[theme.breakpoints.up("md")]: {
			marginBottom: theme.spacing(10.5) + "px !important",
			marginTop: theme.spacing(10.5) + "px !important"
		}
	}
}));

export default styles;
