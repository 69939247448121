import React from "react";
import loadable from "@loadable/component";

import styles from "./Tour.styles";

const Container = loadable(() => import("@Components/views/Container"));
const Detail = loadable(() => import("@micado-digital/react-tourism-tour/views/Detail02"));

const Tour = () => {
	const css = styles();

	return (
		<Container className={css.tour}>
			<Detail
				galleryMediaFormats={{ xs: "tour-gallery" }}
				hutsCategories={[5]}
				mapType="contwise"
				mapURL="https://maps.kaiserwinkl.com"
				teaserMediaFormats={{
					xs: "tour-teaser",
					sm: "tour-teaser-small"
				}}
				thumbsMediaFormats={{ xs: "tour-gallery-thumbs" }}
			/>
		</Container>
	);
};

export default Tour;
