import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	bookingButton: {
		display: "flex",
		justifyContent: "flex-end",
		"& button": {
			width: "100%"
		},
		[theme.breakpoints.up("sm")]: {
			"& button": {
				width: "auto"
			}
		}
	}
}));

export default styles;
