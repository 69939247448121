import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	quickLinks: {
		alignItems: "center",
		display: "flex",
		marginLeft: "auto",
		[theme.breakpoints.up("sm")]: {
			gap: theme.spacing(0.5)
		}
	},
	item: {
		alignItems: "center",
		color: theme.palette.text.primary,
		cursor: "pointer",
		display: "flex",
		gap: 12
	},
	itemWeather: {
		display: "none",
		[theme.breakpoints.up("sm")]: {
			display: "flex"
		}
	},
	itemCrossCountry: {
		display: "none",
		[theme.breakpoints.up(1280)]: {
			display: "flex"
		}
	},
	itemLift: {
		display: "none",
		[theme.breakpoints.up(1280)]: {
			display: "flex"
		}
	},
	itemSwimming: {
		display: "none",
		[theme.breakpoints.up(1280)]: {
			display: "flex"
		}
	},
	itemWebcam: {
		display: "none",
		[theme.breakpoints.up(1280)]: {
			display: "flex"
		}
	},
	value: {
		display: "none",
		fontSize: "20px !important",
		fontWeight: "700 !important",
		lineHeight: "1 !important",
		[theme.breakpoints.up(1050)]: {
			display: "flex"
		}
	},
	link: {
		color: theme.palette.text.primary,
		transition: "color 500ms ease"
	},
	button: {
		height: 32,
		position: "relative",
		transition: "background 500ms ease",
		width: 32,
		"&:hover": {
			background: `${theme.palette.primary.main} !important`,
			"& .mco-view-component-header-quick-links__counter": {
				background: theme.palette.primary.dark
			}
		},
		"& svg": {
			"& path": {
				fill: `${theme.palette.text.primary} !important`
			}
		},
		[theme.breakpoints.up(374)]: {
			height: 40,
			width: 40
		}
	},
	counter: {
		alignItems: "center",
		background: theme.palette.primary.main,
		borderRadius: "10px",
		color: "#FFFFFF",
		display: "flex",
		fontSize: 12,
		fontWeight: 700,
		height: 20,
		justifyContent: "center",
		lineHeight: 1,
		minWidth: 20,
		padding: "0 3px",
		position: "absolute",
		right: 0,
		top: -2,
		transition: "background 500ms ease"
	}
}));

export default styles;
