import React, { useContext, useEffect, useRef, useState } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import PageContext from "@PageContext";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import refsToAnchorItems from "@micado-digital/react-tourism-provider/utils/refsToAnchorItems";

import styles from "./Provider.styles";

const AnchorMenu = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/AnchorMenu")
);
const Animation = loadable(() => import("./Animation"));
const Attributes = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Attributes")
);
const Banner = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Banner")
);
const Breadcrumb = loadable(() => import("@Components/views/Breadcrumb"));
const Contact = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Contact")
);
const Container = loadable(() => import("@Components/views/Container"));
const CurrentInformation = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/CurrentInformation")
);
const Description = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Description")
);
const Descriptions = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Descriptions")
);
const Detail = loadable(() => import("@micado-digital/react-tourism-provider/ProviderDetail"));
const Gallery = loadable(() => import("./Gallery"));
const Information = loadable(() => import("./Information"));
const LocationArrival = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/LocationArrival")
);
const Packages = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Packages")
);
const Provider = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Provider")
);
const Request = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Request")
);
const Rooms = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Rooms")
);
const Videos = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/Detail02/Videos")
);

const ProviderDetail = () => {
	const [pageData] = useContext(PageContext);
	const { lang, setupDetails: data } = pageData;
	const [anchorItems, setAnchorItems] = useState([]);
	const [products, setProducts] = useState([]);
	const bookingRef = useRef(null);
	const refs = useRef({});
	const sysLinks = useSystemLinks();
	const css = styles();

	useEffect(() => {
		setTimeout(() => {
			setAnchorItems(refsToAnchorItems(refs?.current));
		}, 1000);
	}, []);

	const handleAfterLoading = products => {
		setProducts(products);
	};

	const handleScrollTo = mode => {
		let element = refs.current?.[mode];

		if (!element) return;

		element.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<Detail data={data} lang={lang} pagename={window.location.pathname}>
			<div className={clsx(css.providerDetail, "mco-provider-detail")}>
				<div className={clsx(css.header, "mco-provider-detail__header")}>
					<Animation
						bookingRef={bookingRef}
						products={products}
						onBookingClick={() => handleScrollTo("rooms")}
						onVideoClick={() => handleScrollTo("videos")}
					/>
					<Information
						bookingRef={bookingRef}
						products={products}
						refs={refs}
						onBookingClick={() => handleScrollTo("rooms")}
						onMapClick={() => handleScrollTo("map")}
					/>
				</div>
				<Breadcrumb />
				<Container className={clsx(css.elements, "mco-provider-detail__elements")}>
					<Description />
					<CurrentInformation showHeadline />
					<Gallery />
					<Rooms
						cartVersion={1}
						refs={refs}
						systemLinks={{
							cart: sysLinks("cart")
						}}
						onAfterLoading={handleAfterLoading}
					/>
					<Packages />
					<Description type="conditions" />
					<Contact refs={refs} />
					<LocationArrival />
					<Descriptions />
					<Attributes refs={refs} showLabels />
					<Provider />
					<Videos refs={refs} />
					<Request refs={refs} />
				</Container>

				{!isEmpty(anchorItems) && <AnchorMenu items={anchorItems} onClick={handleScrollTo} />}
				<Banner
					bookingRef={bookingRef}
					product={products?.[0]}
					refs={refs}
					onClick={() => handleScrollTo("rooms")}
				/>
			</div>
		</Detail>
	);
};

export default ProviderDetail;
