import React from "react";
import loadable from "@loadable/component";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";

import styles from "./Service.styles";

const Container = loadable(() => import("@Components/views/Container"));
const Detail = loadable(() => import("@micado-digital/react-tourism-service/views/Detail02"));

const Service = () => {
	const sysLink = useSystemLinks();
	const css = styles();

	return (
		<Container className={css.service}>
			<Detail
				cartURL={sysLink("tourism-cart-services")}
				cartVersion={2}
				galleryMediaFormats={{ xs: "product-gallery" }}
				teaserMediaFormats={{
					xs: "product-teaser",
					sm: "product-teaser-small"
				}}
				thumbsMediaFormats={{ xs: "product-gallery-thumbs" }}
			/>
		</Container>
	);
};

export default Service;
