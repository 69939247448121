import React, { useContext, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { motion } from "framer-motion";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PageContext from "@PageContext";
import BookingButton from "../Header/BookingButton";
import QuickLinks from "../QuickLinks";

import styles from "./Animation.styles";

const ReactAnimation = loadable(() =>
	import("@micado-digital/react-animation/ReactAnimation")
);
const Typography = loadable(() => import("@micado-digital/react-ui/components/Typography"));

const Animation = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { animation, animationID, lang, layoutVariant } = pageData;
	const [textArr, setTextArr] = useState([]);
	const [animateTime, setAnimateTime] = useState(new Date());
	const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));
	const $wrapper = useRef(null);
	const css = styles();

	const isIntro = layoutVariant === "intro";

	const animationContainer = {
		animate: {
			transition: {
				staggerChildren: 0.2
			}
		}
	};

	const animationItem = {
		initial: {
			opacity: 0
		},
		animate: {
			opacity: 1,
			transition: {
				stiffness: 10,
				type: "spring"
			}
		}
	};

	const mediaFormats = useMemo(() => {
		if (isIntro) {
			return {
				xs: "animation-intro-mobile",
				sm: "animation-intro-tablet",
				md: "animation-intro"
			};
		}

		return {
			xs: "animation-mobile",
			sm: "animation-tablet",
			lg: "animation-hd",
			xl: "animation"
		};
	}, [isIntro]);

	const handleSlideChange = (slide = {}) => {
		const { texts } = slide;

		setAnimateTime(new Date());
		setTextArr(texts);
	};

	return (
		<div
			className={clsx(css.animationWrapper, "mco-view-component-header-animation-wrapper", {
				[css.animationWrapperIntro]: isIntro,
				"mco-view-component-header-animation-wrapper--intro": isIntro
			})}
			ref={$wrapper}
		>
			<div className={clsx(css.animation, "mco-view-component-animation")}>
				<ReactAnimation
					api={`${REACT_APP_PATH}/animationV3.json.api`}
					data={animation}
					hideTexts
					id={animationID}
					lang={lang}
					mediaFormats={mediaFormats}
					onSlideChange={handleSlideChange}
					showArrows={false}
					showBullets
					webpFallback
				/>
				<motion.div
					className={clsx(css.textSlides, "mco-view-component-animation__text-slides")}
					animate="animate"
					key={animateTime.getTime()}
					initial="initial"
					variants={animationContainer}
				>
					{isIntro && (
						<Typography
							className={clsx(css.hash, "mco-view-component-animation__hash")}
							component="p"
							dangerouslySetInnerHTML={{ __html: "#wow_kaiserwinkl" }}
							variants={{
								xs: "body-medium",
								md: "body-large"
							}}
						/>
					)}
					{textArr?.map((item, counter) => {
						const { text, textColor } = item;

						return (
							<motion.div
								className={clsx(css.textWrapper, "mco-view-component-animation__text-wrapper")}
								key={counter}
								variants={animationItem}
							>
								<Typography
									className={clsx(
										css.text,
										"mco-view-component-animation__text-" + (counter + 1)
									)}
									component="p"
									dangerouslySetInnerHTML={{ __html: text }}
									variant="display-large"
									style={textColor ? { color: `#${textColor}` } : {}}
								/>
							</motion.div>
						);
					})}
					{isIntro && (
						<div className={clsx(css.quickLinks, "mco-view-component-animation__quick-links")}>
							<QuickLinks />
						</div>
					)}
				</motion.div>
				{isDesktop && isIntro && (
					<div className={clsx(css.line, "mco-view-component-animation__line")}>
						<motion.svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 672 74"
							preserveAspectRatio="none"
						>
							<motion.path
								initial={{ pathLength: 0 }}
								animate={{ pathLength: 1 }}
								transition={{
									duration: 5,
									ease: "easeInOut"
								}}
								stroke="#0086CD"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={6}
								fill="none"
								d="M3.5 70.5C59.791 54.5435 115.689 18.9411 127.656 11.0631C129.085 10.1217 130.916 10.1866 132.28 11.221C153.982 27.6772 191.307 57.3612 255.5 53.5C296.385 51.0408 334 25.5 349.5 12C352 20.5 358.5 32.6786 371.5 35C386.5 37.6786 399 31 405.5 26C419.5 39 449 64 517 48.5C557.458 39.2779 582.5 20.5 599.5 3C613.5 18 650 45 668.5 54"
							/>
							<img src="/img/line.svg" alt="" />
						</motion.svg>
					</div>
				)}
				{isIntro && (
					<div
						className={clsx(css.bookingButton, "mco-view-component-animation__booking-button")}
					>
						<BookingButton />
					</div>
				)}
			</div>
		</div>
	);
};

export default Animation;
