const getCartWidgetCounter = identifier => {
	const json = JSON.parse(localStorage.getItem(`deskline/web/${identifier}_data`));

	for (const key in json) {
		if (key === "UserContextBase.shoppingList") {
			return json?.[key]?.numberOfNotBookedItems ?? 0;
		}
	}
};

export default getCartWidgetCounter;
