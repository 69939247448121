import React from "react";
import loadable from "@loadable/component";

import styles from "./Infrastructure.styles";

const Container = loadable(() => import("@Components/views/Container"));
const Detail = loadable(() =>
	import("@micado-digital/react-tourism-infrastructure/views/Detail02")
);

const Infrastructure = () => {
	const css = styles();

	return (
		<Container className={css.infrastructure}>
			<Detail
				galleryMediaFormats={{ xs: "infrastructure-gallery" }}
				teaserMediaFormats={{
					xs: "infrastructure-teaser",
					sm: "infrastructure-teaser-small"
				}}
				thumbsMediaFormats={{ xs: "infrastructure-gallery-thumbs" }}
			/>
		</Container>
	);
};

export default Infrastructure;
