/* eslint-disable no-dupe-keys */
import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	animationWrapper: {
		height: "75vh",
		maxHeight: 500,
		[theme.breakpoints.up("sm")]: {
			maxHeight: "75vh"
		}
	},
	animationWrapperIntro: {
		height: "100vh",
		height: "100dvh",
		maxHeight: "100vh",
		maxHeight: "100dvh",
		"& .mco-view-component-animation": {
			position: "relative",
			"&::before": {
				background:
					"linear-gradient(180deg, rgba(255, 255, 255, 0.00) 42.2%, rgba(255, 255, 255, 0.80) 63.52%)",
				bottom: 0,
				content: "''",
				left: 0,
				position: "absolute",
				right: 0,
				top: 0,
				zIndex: 2,
				[theme.breakpoints.up("md")]: {
					display: "none"
				}
			}
		},
		"& .mco-animation-01__bullets": {
			display: "none"
		},
		[theme.breakpoints.up("md")]: {
			"& .mco-animation-01__bullets": {
				display: "flex"
			}
		},
		[theme.breakpoints.up(1920)]: {
			"& .mco-view-component-animation__hash": {
				left: -205,
				marginBottom: 0,
				position: "absolute",
				textAlign: "center",
				top: "50%",
				transform: "rotate(-90deg) translate(0, 0)",
				transformOrigin: "top",
				width: 310
			}
		},
		[theme.breakpoints.up("md")]: {
			"& .mco-animation-01__wrapper": {
				paddingLeft: "43.3333%"
			},
			"& .mco-view-component-animation__text-slides": {
				bottom: "auto",
				top: "50%",
				transform: "translate(-50%, -50%)"
			}
		}
	},
	animation: {
		height: "100%",
		position: "relative",
		"& .mco-animation-01": {
			overflow: "visible !important"
		},
		"& .mco-animation-01__slide-item": {
			"& .mco-animation-01__image": {
				overflow: "hidden",
				"& img": {
					animation: "ken-burns 30s infinite ease"
				}
			}
		},
		"& .mco-animation-01__sound-control": {
			display: "none !important"
		},
		"& .mco-animation-01__bullets": {
			bottom: theme.spacing(4),
			gap: theme.spacing(1),
			justifyContent: "flex-start",
			left: "50%",
			maxWidth: 1688,
			padding: theme.spacing(0, 3),
			transform: "translate(-50%, 0)",
			width: "100%",
			"& .mco-animation-01__bullet": {
				background: "none",
				border: `2px solid ${theme.palette.text.primary}`,
				height: 16,
				margin: 0,
				opacity: 1,
				position: "relative",
				transition: "border-color 500ms ease",
				width: 16,
				"&::before": {
					animation: "scale 500ms ease"
				},
				"&--selected": {
					"&::before": {
						background: theme.palette.text.primary,
						borderRadius: "50%",
						content: "''",
						height: 8,
						left: "50%",
						position: "absolute",
						top: "50%",
						transform: "translate(-50%, -50%)",
						transformOrigin: "left top",
						transition: "background 500ms ease",
						width: 8
					}
				},
				"&:hover": {
					borderColor: theme.palette.primary.main,
					"&.mco-animation-01__bullet--selected": {
						"&::before": {
							background: theme.palette.primary.main
						}
					}
				}
			}
		}
	},
	textSlides: {
		bottom: theme.spacing(12),
		left: "50%",
		maxWidth: 1688,
		padding: theme.spacing(0, 3),
		position: "absolute",
		textAlign: "left",
		transform: "translate(-50%, 0)",
		userSelect: "none",
		zIndex: 2,
		width: "100%",
		[theme.breakpoints.up(800)]: {
			bottom: theme.spacing(3)
		},
		[theme.breakpoints.up("md")]: {
			bottom: theme.spacing(7)
		}
	},
	hash: {
		fontSize: "30px !important",
		hyphens: "auto",
		lineHeight: "1 !important",
		marginBottom: theme.spacing(3),
		wordBreak: "break-word"
	},
	text: {
		fontSize: "clamp(28px, 6vw, 100px) !important",
		hyphens: "auto",
		letterSpacing: "0.6px !important",
		textTransform: "uppercase",
		wordBreak: "break-word",
		[theme.breakpoints.up("md")]: {
			letterSpacing: "2px !important"
		}
	},
	line: {
		bottom: theme.spacing(8.75),
		left: "50%",
		maxWidth: 1688,
		padding: theme.spacing(0, 3),
		position: "absolute",
		transform: "translate(-50%, 0)",
		zIndex: 2,
		width: "100%",
		"& svg": {
			display: "block",
			maxWidth: "38.41463414634146%",
			width: "100%"
		}
	},
	bookingButton: {
		bottom: theme.spacing(3),
		left: theme.spacing(3),
		position: "absolute",
		right: theme.spacing(3),
		zIndex: 2,
		[theme.breakpoints.up("sm")]: {
			left: "50%",
			maxWidth: 1688,
			padding: theme.spacing(0, 3),
			right: "auto",
			transform: "translate(-50%, 0)",
			width: "100%"
		},
		[theme.breakpoints.up("md")]: {
			bottom: theme.spacing(8.75)
		}
	},
	quickLinks: {
		margin: theme.spacing(3, 0, 0),
		[theme.breakpoints.up("sm")]: {
			maxWidth: 375
		},
		[theme.breakpoints.up("md")]: {
			display: "none"
		}
	}
}));

export default styles;
