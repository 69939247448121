import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	desktopMenu: {
		marginLeft: theme.spacing(5),
		[theme.breakpoints.up(1500)]: {
			left: "50%",
			marginLeft: 0,
			position: "absolute",
			top: "50%",
			transform: "translate(-50%, -50%)"
		}
	}
}));

export default styles;
