import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import { motion } from "framer-motion";

import styles from "./Logo.styles";

const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));

const Logo = () => {
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const setUi = useContext(UiContext)?.[1];
	const css = styles();

	const homeLink = `${lang === `de` ? `/` : `/${lang}/`}`;

	const handleClick = () => {
		setUi(prevState => ({
			...prevState,
			currentSubMenuID: null,
			overlayOpen: null
		}));
	};

	return (
		<motion.div
			className={clsx(css.logo, "mco-view-component-header-logo")}
			initial={{
				opacity: 0,
				y: -24
			}}
			animate={{
				opacity: 1,
				y: 0
			}}
			transition={{
				delay: 0.8
			}}
		>
			<ReactLink aria-label="TVB Kaiserwinkl" onClick={handleClick} to={homeLink}>
				<img alt="TVB Kaiserwinkl" src="/img/logo.svg" height={107} width={260} />
			</ReactLink>
		</motion.div>
	);
};

export default Logo;
