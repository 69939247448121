import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	quickLinks: {
		boxShadow: "0px 12px 24px 0px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.12)",
		display: "flex",
		gap: 2
	},
	item: {
		display: "flex",
		width: "100%"
	},
	link: {
		alignItems: "center",
		alignSelf: "stretch",
		background: theme.palette.background.light,
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(1),
		justifyContent: "center",
		padding: theme.spacing(1.5),
		transition: "background 300ms ease",
		width: "100%",
		"& svg path": {
			transition: "fill 300ms ease"
		},
		"&:hover": {
			background: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
			"& svg path": {
				fill: theme.palette.getContrastText(theme.palette.primary.main)
			}
		}
	},
	label: {
		display: "none",
		fontSize: 12,
		letterSpacing: 0.5,
		lineHeight: 1.1,
		[theme.breakpoints.up(375)]: {
			display: "block"
		}
	}
}));

export default styles;
