import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./QuickLinks.styles";

const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));

const QuickLinks = () => {
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const sysLink = useSystemLinks();
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const crossCountryURL = sysLink("cross-country");
	const liftsURL = sysLink("lifts");
	const swimmingURL = sysLink("swimming");
	const weatherURL = sysLink("weather");
	const webcamsURL = sysLink("webcams");

	return (
		<div className={clsx(css.quickLinks, "mco-view-component-quick-links")}>
			{weatherURL && (
				<div className={clsx(css.item, "mco-view-component-quick-links__item")}>
					<ReactLink
						className={clsx(css.link, "mco-view-component-quick-links__link")}
						to={weatherURL}
					>
						<ReactSVGIcon src="/img/icons/weather.svg" size={40} />
						<span
							className={clsx(css.label, "mco-view-component-quick-links__label")}
							dangerouslySetInnerHTML={{ __html: l("quickLinks.labels.weather") }}
						/>
					</ReactLink>
				</div>
			)}
			{webcamsURL && (
				<div className={clsx(css.item, "mco-view-component-quick-links__item")}>
					<ReactLink
						className={clsx(css.link, "mco-view-component-quick-links__link")}
						to={webcamsURL}
					>
						<ReactSVGIcon src="/img/icons/webcam.svg" size={40} />
						<span
							className={clsx(css.label, "mco-view-component-quick-links__label")}
							dangerouslySetInnerHTML={{ __html: l("quickLinks.labels.webcams") }}
						/>
					</ReactLink>
				</div>
			)}
			{liftsURL && (
				<div className={clsx(css.item, "mco-view-component-quick-links__item")}>
					<ReactLink
						className={clsx(css.link, "mco-view-component-quick-links__link")}
						to={liftsURL}
					>
						<ReactSVGIcon src="/img/icons/lift.svg" size={40} />
						<span
							className={clsx(css.label, "mco-view-component-quick-links__label")}
							dangerouslySetInnerHTML={{ __html: l("quickLinks.labels.lifts") }}
						/>
					</ReactLink>
				</div>
			)}
			{crossCountryURL && (
				<div className={clsx(css.item, "mco-view-component-quick-links__item")}>
					<ReactLink
						className={clsx(css.link, "mco-view-component-quick-links__link")}
						to={crossCountryURL}
					>
						<ReactSVGIcon src="/img/icons/pist.svg" size={40} />
						<span
							className={clsx(css.label, "mco-view-component-quick-links__label")}
							dangerouslySetInnerHTML={{ __html: l("quickLinks.labels.crossCountry") }}
						/>
					</ReactLink>
				</div>
			)}
			{swimmingURL && (
				<div className={clsx(css.item, "mco-view-component-quick-links__item")}>
					<ReactLink
						className={clsx(css.link, "mco-view-component-quick-links__link")}
						to={swimmingURL}
					>
						<ReactSVGIcon src="/img/icons/swimming.svg" size={40} />
						<span
							className={clsx(css.label, "mco-view-component-quick-links__label")}
							dangerouslySetInnerHTML={{ __html: l("quickLinks.labels.swimming") }}
						/>
					</ReactLink>
				</div>
			)}
		</div>
	);
};

export default QuickLinks;
