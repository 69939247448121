import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logo: {
		display: "block",
		"& img": {
			display: "block",
			height: "33px !important",
			width: "80px !important"
		},
		[theme.breakpoints.up(374)]: {
			"& img": {
				height: "44px !important",
				width: "107px !important"
			}
		},
		[theme.breakpoints.up("sm")]: {
			"& img": {
				height: "70px !important",
				width: "170px !important"
			}
		},
		[theme.breakpoints.up(1500)]: {
			"& img": {
				height: "107px !important",
				width: "260px !important"
			}
		}
	}
}));

export default styles;
