import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	providerDetail: {
		[theme.breakpoints.up("md")]: {
			marginTop: -150
		},
		"& .mco-provider-detail-bookmark__item": {
			background: theme.palette.common.black,
			border: "none",
			"& svg path": {
				fill: theme.palette.common.white + " !important"
			}
		}
	},
	header: {
		display: "flex",
		flexDirection: "column",
		position: "relative",
		[theme.breakpoints.up("md")]: {
			flexDirection: "row-reverse"
		}
	},
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(7),
		scrollMarginTop: "72px",
		paddingBottom: theme.spacing(10),
		position: "relative",
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(7)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(7)
		},
		[theme.breakpoints.up("md")]: {
			gap: theme.spacing(10),
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(10)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(10)
			}
		}
	}
}));

export default styles;
