import React, { useContext } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";
import { Spin as UiHamburger } from "hamburger-react";

import styles from "./Hamburger.styles";

const Hamburger = () => {
	const css = styles();
	const [ui, setUi] = useContext(UiContext);
	const { overlayOpen } = ui;

	const handleClick = () => {
		setUi(prevState => ({
			...prevState,
			overlayOpen: overlayOpen === "menu" ? null : "menu"
		}));
	};

	return (
		<div className={clsx(css.hamburger, "mco-view-component-header-hamburger")}>
			<UiHamburger
				label="Menu"
				role="menu"
				size={29}
				toggled={overlayOpen === "menu"}
				toggle={handleClick}
			/>
		</div>
	);
};

export default Hamburger;
