import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { useLocation } from "react-router-dom";
import UiContext from "@UiContext";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";
import PageContext from "@PageContext";
import WebProfileContext from "@WebProfileContext";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import useConsent from "@Hooks/useConsent";
import ReactMetaData01 from "@micado-digital/react-metadata/ReactMetadata01";
import Locals from "@micado-digital/react-ui/components/Locals";
import Animation from "@Components/views/Animation";
import Header from "@Components/views/Header";
import Default from "./Default";
import Event from "./Event";
import Infrastructure from "./Infrastructure";
import Package from "./Package";
import Provider from "./Provider";
import Service from "./Service";
import Tour from "./Tour";

import styles from "./Views.styles";

const Breadcrumb = loadable(() => import("@Components/views/Breadcrumb"));
const Footer = loadable(() => import("@Components/views/Footer"));
const ReactTracking = loadable(() => import("@micado-digital/react-tracking/ReactTracking"));

const Tracking = () => {
	const [profileData] = useContext(WebProfileContext);
	const { trackingCodes = [] } = profileData;

	return <ReactTracking codes={trackingCodes} />;
};

const Views = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { authorID, homepage, id, lang, layoutTag, layoutVariant, meta = {} } = pageData;
	const setUi = useContext(UiContext)[1];
	const consent = useConsent();
	const location = useLocation();
	const css = styles();

	const hasAnimation =
		layoutVariant !== "no-animation" &&
		layoutTag !== "tourism-event-detail" &&
		layoutTag !== "tourism-infrastructure-detail" &&
		layoutTag !== "tourism-package-detail" &&
		layoutTag !== "tourism-provider-detail" &&
		layoutTag !== "tourism-service-detail" &&
		layoutTag !== "tourism-tour-detail";
	const isIntro = layoutVariant === "intro";

	useEffect(() => {
		const hash = document.URL.split("#")[1];

		if (hash) {
			setTimeout(() => {
				const $target = document.querySelector(`[data-anchor="${hash}"]`);

				if ($target) {
					$target.scrollIntoView({ behavior: "smooth", block: "start" });
				}
			}, 100);
		}
	}, [location]);

	useEffect(() => {
		setUi(prevState => ({
			...prevState,
			currentSubMenuID: null,
			overlayOpen: null
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document.URL]);

	if (!lang) return null;

	const {
		canonical: metaCanonical,
		description: metaDescription,
		indexable: metaIndexable,
		image: metaImage,
		keywords: metaKeywords,
		lat: metaLat,
		links: metaLinks,
		lng: metaLng,
		title: metaTitle
	} = meta;

	return (
		<WebProfile>
			<AccountData authorID={authorID}>
				<Locals url={`/locals/${lang || "de"}.json`}>
					<div
						className={clsx(css.page, "mco-page", {
							[css.pageIntro]: isIntro,
							"mco-page--intro": isIntro
						})}
					>
						<Header lang={lang} />
						{hasAnimation && <Animation />}
						{!homepage &&
							layoutTag !== "tourism-package-detail" &&
							layoutTag !== "tourism-provider-detail" && <Breadcrumb />}
						{layoutTag === "default" && <Default />}
						{layoutTag === "tourism-event-detail" && <Event />}
						{layoutTag === "tourism-infrastructure-detail" && <Infrastructure />}
						{layoutTag === "tourism-package-detail" && <Package />}
						{layoutTag === "tourism-provider-detail" && <Provider />}
						{layoutTag === "tourism-service-detail" && <Service />}
						{layoutTag === "tourism-tour-detail" && <Tour />}
						<Footer />
						<micado-ial
							key={`${consent}-${id}`}
							language={lang}
							path={REACT_APP_PATH}
							page-id={id}
						></micado-ial>
						<PageTransition color="rgba(0,0,0,0)" position="top" />
					</div>
					<ReactMetaData01
						canonical={metaCanonical}
						description={metaDescription}
						index={metaIndexable}
						image={metaImage}
						keywords={metaKeywords}
						lat={metaLat}
						lang={lang}
						links={metaLinks}
						lng={metaLng}
						title={metaTitle}
					/>
					<Tracking />
				</Locals>
			</AccountData>
		</WebProfile>
	);
};

export default Views;
