import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export const theme = {
	palette: {
		primary: {
			light: "#D3DB33",
			main: "#C8D300",
			dark: "#8C9300"
		},
		secondary: {
			light: "#0188cf",
			main: "#0086cd",
			dark: "#005D8F"
		},
		background: {
			default: "#FFFFFF",
			header: "#FFFFFF",
			menu: "#FFFFFF",
			footer: "#E6E6E6",
			light: "#f8f8f7",
			light2: "#f8f8f7",
			dark: "#A1A1A1"
		},
		text: {
			primary: "#000000",
			secondary: "#7F7F7F",
			disabled: "#CCCCCC"
		},
		grey: {
			50: "#f8f8f7",
			100: "#e6e6e6",
			200: "#d3d3cf",
			300: "#b6b6af",
			400: "#9e9e94",
			500: "#868579",
			600: "#6b6b61",
			700: "#505049",
			800: "#363530",
			900: "#1b1b18",
			A100: "#d3d3cf",
			A200: "#b6b6af",
			A400: "#363530",
			A700: "#6b6b61"
		},
		common: {
			white: "#FFFFFF",
			black: "#000000"
		},
		info: {
			light: "#77B6DA",
			main: "#0175B9",
			dark: "#01446C"
		},
		success: {
			light: "#A4BF7A",
			main: "#70992E",
			dark: "#477301"
		},
		warning: {
			light: "#FFDE77",
			main: "#FFC105",
			dark: "#D19E05"
		},
		error: {
			light: "#F08080",
			main: "#D50B0B",
			dark: "#8B0101"
		},
		divider: "#707070",
		shape: {
			borderRadius: 0
		}
	},
	typography: {
		fontFamily: "Montserrat",
		"display-large": {
			fontFamily: "Montserrat",
			fontWeight: 800,
			fontSize: "clamp(28px, 6vw, 64px)",
			letterSpacing: 0.6,
			lineHeight: 1,
			textTransform: "uppercase",
			[breakpoints.up("md")]: {
				letterSpacing: 2
			}
		},
		"display-medium": {
			fontFamily: "Montserrat",
			fontWeight: 800,
			fontSize: "clamp(26px, 5vw, 56px)",
			letterSpacing: 0.6,
			lineHeight: 1.1,
			textTransform: "uppercase",
			[breakpoints.up("md")]: {
				letterSpacing: 2
			}
		},
		"display-small": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: "clamp(24px, 4vw, 48px)",
			letterSpacing: 0,
			lineHeight: 1.1,
			textTransform: "uppercase"
		},
		"headline-large": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: "clamp(24px, 4vw, 38px)",
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		"headline-medium": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: "clamp(24px, 4vw, 36px)",
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		"headline-small": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: "clamp(24px, 4vw, 32px)",
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		"title-large": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 28,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		"title-medium": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 24,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		"title-small": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 18,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		"body-large": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0,
			lineHeight: 1.33
		},
		"body-medium": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0,
			lineHeight: 1.33
		},
		"body-small": {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 16,
			letterSpacing: 0,
			lineHeight: 1.33
		},
		"label-large": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 20,
			letterSpacing: 0,
			lineHeight: 1.2
		},
		"label-medium": {
			fontFamily: "Montserrat",
			fontWeight: 600,
			fontSize: 18,
			letterSpacing: 0,
			lineHeight: 1.2
		},
		"label-small": {
			fontFamily: "Montserrat",
			fontWeight: 600,
			fontSize: 16,
			letterSpacing: 0,
			lineHeight: 1.2
		},
		"button-large": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 20,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: 1.2
		},
		"button-medium": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 18,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: 1.2
		},
		"button-small": {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 16,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: 1.2
		},
		h1: {
			fontFamily: "Montserrat",
			fontWeight: 800,
			fontSize: "clamp(36px, 6vw, 64px)",
			letterSpacing: 0.6,
			lineHeight: 1,
			textTransform: "uppercase",
			[breakpoints.up("md")]: {
				letterSpacing: 2
			}
		},
		h2: {
			fontFamily: "Montserrat",
			fontWeight: 800,
			fontSize: "clamp(32px, 5vw, 56px)",
			letterSpacing: 0.6,
			lineHeight: 1.2,
			textTransform: "uppercase",
			[breakpoints.up("md")]: {
				letterSpacing: 2
			}
		},
		h3: {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: "clamp(32px, 4vw, 48px)",
			letterSpacing: 0,
			lineHeight: 1.1,
			textTransform: "uppercase"
		},
		h4: {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 38,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		h5: {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 36,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		h6: {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 32,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		subtitle1: {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 28,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		subtitle2: {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 24,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		overline: {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 18,
			letterSpacing: "0.46px",
			lineHeight: 1.1
		},
		body1: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0,
			lineHeight: 1.33
		},
		body2: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 16,
			letterSpacing: 0,
			lineHeight: 1.33
		},
		button: {
			fontFamily: "Montserrat",
			fontWeight: 700,
			fontSize: 18,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: 1.2
		},
		caption: {
			fontFamily: "Montserrat",
			fontWeight: 400,
			fontSize: 16,
			letterSpacing: 0,
			lineHeight: 1.33
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				boxShadow: "none !important",
				minHeight: 48,
				paddingLeft: "24px !important",
				paddingRight: "24px !important"
			},
			containedPrimary: {
				"&:hover": {
					color: "#FFFFFF"
				}
			},
			sizeLarge: {
				fontSize: 20,
				minHeight: 64
			},
			sizeSmall: {
				fontSize: 16,
				minHeight: 48
			},
			text: {
				paddingLeft: "0 !important",
				paddingRight: "0 !important",
				"&:hover": {
					background: "none !important"
				},
				"& .MuiTouchRipple-root": {
					display: "none"
				}
			}
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			disableElevation: true
		}
	}
};
