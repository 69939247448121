import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import { motion } from "framer-motion";
import CartDataContext from "@CartDataContext";
import UiContext from "@UiContext";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import getCartWidgetCounter from "../../../../utils/getCartWidgetCounter";

import styles from "./QuickLinks.styles";

const BookmarkCount = loadable(() =>
	import("@micado-digital/react-bookmark/ReactBookmark/bookmarkCount")
);
const IconButton = loadable(() => import("@material-ui/core/IconButton"));
const LangSelection = loadable(() => import("../LangSelection"));
const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));

const QuickLinks = () => {
	const setUi = useContext(UiContext)?.[1];
	const cartData = useContext(CartDataContext)[0];
	const sysLink = useSystemLinks();
	const css = styles();

	const cartWidgetCounter = getCartWidgetCounter("kaiserwinkl");

	const bookmarkURL = sysLink("bookmark");
	const cartURL = cartWidgetCounter > 0 ? sysLink("tourism-cart-services") : sysLink("cart");
	const crossCountryURL = sysLink("cross-country");
	const liftsURL = sysLink("lifts");
	const swimmingURL = sysLink("swimming");
	const weatherURL = sysLink("weather");
	const webcamsURL = sysLink("webcams");

	const animationContainer = {
		animate: {
			transition: {
				delayChildren: 1,
				staggerChildren: 0.2
			}
		}
	};

	const animationItem = {
		initial: {
			opacity: 0,
			y: -24
		},
		animate: {
			opacity: 1,
			transition: {
				stiffness: 20,
				type: "spring"
			},
			y: 0
		}
	};

	const cartCount = cartData?.items?.length ?? 0;

	const handleClick = identifier => {
		setUi(prevState => ({ ...prevState, overlayOpen: identifier }));
	};

	return (
		<motion.div
			animate="animate"
			className={clsx(css.quickLinks, "mco-view-component-header-quick-links")}
			key={bookmarkURL}
			initial="initial"
			variants={animationContainer}
		>
			{weatherURL && (
				<motion.div
					className={clsx(
						css.item,
						css.itemWeather,
						"mco-view-component-header-quick-links__item",
						"mco-view-component-header-quick-links__item--weather"
					)}
					variants={animationItem}
				>
					<ReactLink
						className={clsx(css.link, "mco-view-component-header-quick-links__link")}
						to={weatherURL}
					>
						<IconButton
							aria-label="weather"
							className={clsx(css.button, "mco-view-component-header-quick-links__button")}
						>
							<ReactSVGIcon src="/img/icons/weather.svg" size={40} />
						</IconButton>
					</ReactLink>
				</motion.div>
			)}
			{webcamsURL && (
				<motion.div
					className={clsx(
						css.item,
						css.itemWebcam,
						"mco-view-component-header-quick-links__item",
						"mco-view-component-header-quick-links__item--webcam"
					)}
					variants={animationItem}
				>
					<ReactLink
						className={clsx(css.link, "mco-view-component-header-quick-links__link")}
						to={webcamsURL}
					>
						<IconButton
							aria-label="webcams"
							className={clsx(css.button, "mco-view-component-header-quick-links__button")}
						>
							<ReactSVGIcon src="/img/icons/webcam.svg" size={40} />
						</IconButton>
					</ReactLink>
				</motion.div>
			)}
			{liftsURL && (
				<motion.div
					className={clsx(
						css.item,
						css.itemLift,
						"mco-view-component-header-quick-links__item",
						"mco-view-component-header-quick-links__item--lift"
					)}
					variants={animationItem}
				>
					<ReactLink
						className={clsx(css.link, "mco-view-component-header-quick-links__link")}
						to={liftsURL}
					>
						<IconButton
							aria-label="lifts"
							className={clsx(css.button, "mco-view-component-header-quick-links__button")}
						>
							<ReactSVGIcon src="/img/icons/lift.svg" size={40} />
						</IconButton>
					</ReactLink>
				</motion.div>
			)}
			{crossCountryURL && (
				<motion.div
					className={clsx(
						css.item,
						css.itemCrossCountry,
						"mco-view-component-header-quick-links__item",
						"mco-view-component-header-quick-links__item--cross-country"
					)}
					variants={animationItem}
				>
					<ReactLink
						className={clsx(css.link, "mco-view-component-header-quick-links__link")}
						to={crossCountryURL}
					>
						<IconButton
							aria-label="cross-country"
							className={clsx(css.button, "mco-view-component-header-quick-links__button")}
						>
							<ReactSVGIcon src="/img/icons/cross-country.svg" size={40} />
						</IconButton>
					</ReactLink>
				</motion.div>
			)}
			{swimmingURL && (
				<motion.div
					className={clsx(
						css.item,
						css.itemSwimming,
						"mco-view-component-header-quick-links__item",
						"mco-view-component-header-quick-links__item--swimming"
					)}
					variants={animationItem}
				>
					<ReactLink
						className={clsx(css.link, "mco-view-component-header-quick-links__link")}
						to={swimmingURL}
					>
						<IconButton
							aria-label="swimming"
							className={clsx(css.button, "mco-view-component-header-quick-links__button")}
						>
							<ReactSVGIcon src="/img/icons/swimming.svg" size={42} />
						</IconButton>
					</ReactLink>
				</motion.div>
			)}
			{bookmarkURL && (
				<motion.div
					className={clsx(
						css.item,
						css.itemBookmark,
						"mco-view-component-header-quick-links__item",
						"mco-view-component-header-quick-links__item--bookmark"
					)}
					variants={animationItem}
				>
					<ReactLink
						className={clsx(css.link, "mco-view-component-header-quick-links__link")}
						to={bookmarkURL}
					>
						<IconButton
							aria-label="bookmark"
							className={clsx(css.button, "mco-view-component-header-quick-links__button")}
						>
							<ReactSVGIcon src="/img/icons/bookmark.svg" size={40} />
							<BookmarkCount
								className={clsx(css.counter, "mco-view-component-header-quick-links__counter")}
								hideZero={true}
							/>
						</IconButton>
					</ReactLink>
				</motion.div>
			)}
			{cartURL && (
				<motion.div
					className={clsx(
						css.item,
						css.itemCart,
						"mco-view-component-header-quick-links__item",
						"mco-view-component-header-quick-links__item--cart"
					)}
					variants={animationItem}
				>
					<ReactLink
						className={clsx(css.link, "mco-view-component-header-quick-links__link")}
						to={cartURL}
					>
						<IconButton
							aria-label="cart"
							className={clsx(css.button, "mco-view-component-header-quick-links__button")}
						>
							<ReactSVGIcon src="/img/icons/cart.svg" size={28} />
							{cartCount > 0 && (
								<div
									className={clsx(
										css.counter,
										"mco-view-component-header-quick-links__counter"
									)}
								>
									{cartCount}
								</div>
							)}
						</IconButton>
					</ReactLink>
				</motion.div>
			)}
			<motion.div
				className={clsx(
					css.item,
					css.itemSearch,
					"mco-view-component-header-quick-links__item",
					"mco-view-component-header-quick-links__item--search"
				)}
				variants={animationItem}
			>
				<IconButton
					aria-label="search"
					className={clsx(css.button, "mco-view-component-header-quick-links__button")}
					onClick={() => handleClick("search")}
				>
					<ReactSVGIcon src="/img/icons/search.svg" size={28} />
				</IconButton>
			</motion.div>
			<motion.div
				className={clsx(
					css.item,
					css.itemLang,
					"mco-view-component-header-quick-links__item",
					"mco-view-component-header-quick-links__item--lang"
				)}
				variants={animationItem}
			>
				<LangSelection />
			</motion.div>
		</motion.div>
	);
};

export default QuickLinks;
