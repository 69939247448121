import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	header: {
		background: "rgba(255, 255, 255, 0.24)",
		backdropFilter: "blur(20px)",
		height: 80,
		left: 0,
		position: "fixed",
		right: 0,
		top: 0,
		transition:
			"background 500ms ease, box-shadow 500ms ease, transform 140ms ease-out, height 50ms ease-out",
		userSelect: "none",
		zIndex: theme.zIndex.drawer - 1,
		[theme.breakpoints.up("sm")]: {
			height: 99
		},
		[theme.breakpoints.up("md")]: {
			height: 150
		}
	},
	headerIsScrolling: {
		background: "rgba(255, 255, 255, 0.5)",
		backdropFilter: "blur(20px)",
		boxShadow: "0 30px 50px rgba(136, 136, 136, 0.2)",
		height: 80,
		[theme.breakpoints.up("sm")]: {
			height: "99px !important",
			"& .mco-view-component-header-logo": {
				"& img": {
					height: "70px !important",
					width: "170px !important"
				}
			}
		}
	},
	headerNoAnimation: {
		background: "rgba(255, 255, 255, 0.5)",
		backdropFilter: "blur(20px)",
		boxShadow: "0 30px 50px rgba(136, 136, 136, 0.2)"
	},
	headerIntro: {},
	headerOverlayOpen: {
		background: "rgba(255, 255, 255, 1)",
		backdropFilter: "none"
	},
	container: {
		alignItems: "center",
		display: "flex",
		height: "100%",
		justifyContent: "space-between",
		[theme.breakpoints.up("md")]: {
			justifyContent: "flex-start"
		}
	}
}));

export default styles;
