import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import groupFollowingElementsByTag from "@micado-digital/react-ui/utils/groupFollowingElementsByTag";
import groupExactlyElements from "@micado-digital/react-ui/utils/groupExactlyElements";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import getJSXElement from "./getJSXElement";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Elements.styles";

const Elements = () => {
	const [pageData] = useContext(PageContext);
	let { accountDetailID, authorID, editDate, elements, id, lang, layoutVariant } = pageData;
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const sysLink = useSystemLinks();
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const blogData = {
		accountDetailID: accountDetailID,
		authorID: authorID,
		editDate: editDate,
		id: id
	};

	// group downloads

	elements = groupFollowingElementsByTag(elements, "basic-download-group", "basic-download");

	// group dropdowns

	elements = groupFollowingElementsByTag(
		elements,
		"basic-dropdown-group",
		["basic-dropdown", "*"],
		"basic-dropdown",
		"basic-dropdown"
	);

	// group grid + singleteaser + text combo + text box

	if (layoutVariant === "summer-nature") {
		elements = groupExactlyElements(elements, "extension-variant-combo", [
			"extension-special-grid",
			"basic-singleteaser",
			"headline-text-combo",
			"headline-text-combo"
		]);
	}

	if (layoutVariant === "summer-nature") {
		elements = groupExactlyElements(elements, "extension-variant-combo", [
			"extension-special-grid",
			"basic-singleteaser",
			"headline-text-combo"
		]);
	}

	// exclude elements

	const excludeElementTags = ["basic-animation-textbox"];

	elements = elements.filter(element => {
		return excludeElementTags.indexOf(element.tag) === -1;
	});

	return (
		<div className={clsx(css.elements, "mco-elements")}>
			{elements?.map(element => {
				return getJSXElement(element, lang, l, isMobile, sysLink, blogData, layoutVariant);
			})}
		</div>
	);
};

export default Elements;
