import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import useIsScrolling from "@micado-digital/react-ui/hooks/useIsScrolling";
import duplicateParentAsFirstChild from "@micado-digital/react-ui/utils/duplicateParentAsFirstChild";
import useFindMenuGroup from "@micado-digital/react-ui/hooks/useFindMenuGroup";
import Container from "../Container";
// import Close from "./Close";
import Logo from "./Logo";
import Hamburger from "./Hamburger";
import DesktopMenu from "./DesktopMenu";
import QuickLinks from "./QuickLinks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Header.styles";

const Booking = loadable(() => import("../Booking"));
const MobileMenu = loadable(() => import("./MobileMenu"));
const Search = loadable(() => import("../Search"));

const Header = ({ lang }) => {
	const [ui] = useContext(UiContext);
	const { overlayOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { layoutVariant } = pageData;
	const menuItems = useFindMenuGroup("Hauptmenü");
	const isScrolling = useIsScrolling();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const isIntro = layoutVariant === "intro";
	const hasNoAnimation = layoutVariant === "no-animation";

	useEffect(() => {
		duplicateParentAsFirstChild(menuItems, "");
	}, [menuItems, lang, l]);

	return (
		<div
			className={clsx(css.header, "mco-view-component-header", {
				[css.headerIsScrolling]: isScrolling && !overlayOpen,
				"mco-view-component-header--is-scrolling": isScrolling && !overlayOpen,
				[css.headerOverlayOpen]: overlayOpen,
				"mco-view-component-header--overlay-open": overlayOpen,
				[css.headerIntro]: isIntro && !overlayOpen,
				"mco-view-component-header--intro": isIntro && !overlayOpen,
				[css.headerNoAnimation]: hasNoAnimation && !overlayOpen,
				"mco-view-component-header--no-animation": hasNoAnimation && !overlayOpen
			})}
		>
			<Container className={clsx(css.container, "mco-view-component-header__container")}>
				<Logo />
				{isDesktop && <DesktopMenu items={menuItems} lang={lang} />}
				<QuickLinks />
				{/* {isDesktop && <Close />} */}
				{/* {isMobile && <>{overlayOpen ? <Close /> : <Hamburger />}</>} */}
				{isMobile && <Hamburger />}
				{overlayOpen === "menu" && isMobile && <MobileMenu items={menuItems} />}
				{overlayOpen === "booking" && <Booking />}
				{overlayOpen === "search" && <Search />}
			</Container>
		</div>
	);
};

export default Header;
