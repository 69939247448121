import React, { useContext, useState } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";

import loadable from "@loadable/component";

import styles from "./DesktopMenu.styles";

const Level1 = loadable(() => import("./Level1"));
const Level2 = loadable(() => import("./Level2"));

const DesktopMenu = ({ items }) => {
	const css = styles();
	const [ui, setUi] = useContext(UiContext);
	const { overlayOpen, currentSubMenuID, currentSubMenuTag } = ui;
	const [subItems, setSubItems] = useState([]);

	const handleLevel1Click = ({ id, subItems, tag }) => {
		if (id === currentSubMenuID) {
			setSubItems([]);
			setUi(prevState => ({
				...prevState,
				overlayOpen: null,
				currentSubMenuID: null,
				currentSubMenuTag: null
			}));
			return;
		}

		setSubItems(subItems);
		setUi(prevState => ({
			...prevState,
			overlayOpen: "menu",
			currentSubMenuID: id,
			currentSubMenuTag: tag
		}));
	};

	return (
		<div className={clsx(css.desktopMenu, "mco-view-component-header-desktop-menu")}>
			<Level1
				currentSubMenuID={currentSubMenuID}
				items={items}
				onItemClick={handleLevel1Click}
			/>
			{overlayOpen === "menu" && (
				<Level2 currentSubMenuTag={currentSubMenuTag} items={subItems} />
			)}
		</div>
	);
};

export default DesktopMenu;
