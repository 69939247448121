import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import merge from "lodash/merge";
import createTheme from "@material-ui/core/styles/createTheme";
import { alpha } from "@material-ui/core/styles/";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme as customTheme } from "./themes/project";
import { theme as winterTheme } from "./themes/winter";
import CSSProperties from "./utils/CSSProperties";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import BookmarkRoot from "@micado-digital/react-bookmark/ReactBookmark/bookmarkRoot";
import Ui from "@micado-digital/react-ui/components/Ui";
import CartDataContext from "@CartDataContext";
import Views from "./views";
import "./css/index.css";
import {
	applyPolyfills as ialApplyPolyfills,
	defineCustomElements as ialDefineCustomElements
} from "@micado-digital/micado-ial/loader";

const theme =
	process.env.REACT_APP_SEASON === "winter"
		? createTheme(merge(customTheme, winterTheme))
		: createTheme(customTheme);

const App = () => {
	const [cartData, setCartData] = useState({});

	useEffect(() => {
		CSSProperties(theme, alpha);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui>
						<BookmarkRoot>
							<WebPageData>
								<CartDataContext.Provider value={[cartData, setCartData]}>
									<Routes>
										<Route path="/*" element={<Views />} />
									</Routes>
								</CartDataContext.Provider>
							</WebPageData>
						</BookmarkRoot>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

ialApplyPolyfills().then(() => {
	ialDefineCustomElements(window);
});
