/* eslint-disable no-undef */
import React, { useContext, useEffect, useRef, useState } from "react";
import loadable from "@loadable/component";
import PageContext from "@PageContext";

import styles from "./Package.styles";

const Container = loadable(() => import("@Components/views/Container"));

const Package = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const [loaded, setLoaded] = useState(false);
	const $intervalRef = useRef(null);
	const $scriptLoaded = useRef(false);
	const css = styles();

	const identifier = "ea78d8d0-a208-4506-90fd-4648f1524aaa";

	useEffect(() => {
		if (!identifier) return;

		window.dw =
			window.dw ||
			function () {
				(dw.q = dw.q || []).push(arguments);
			};

		if (typeof window.dw.destroyTag === "function") {
			dw.destroyTag(identifier);
		}

		const script = document.createElement("script");

		script.src = "https://web5.deskline.net/dwutility.js";
		document.head.appendChild(script);

		script.addEventListener("load", () => {
			setLoaded(true);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifier]);

	useEffect(() => {
		if (!loaded) return;

		const checkLoaded = () => {
			return typeof window.dw === "function";
		};

		$intervalRef.current = setInterval(() => {
			if ($scriptLoaded.current) {
				clearInterval($intervalRef.current);
				return;
			}

			const result = checkLoaded();
			const targetRoute = lang === "de" ? "/spezialangebote" : "/specialoffers";

			if (result) {
				$scriptLoaded.current = true;

				dw("settings", identifier, {
					context: {
						targetRoute: [targetRoute]
					},
					lang: "de",
					styleResources: [`${REACT_APP_PATH}/static/css/tosc-package.css`],
					target: "tosc5target-shop",
					profileOverrides: ["general.navigation.visible = false"]
				});

				dw("onGetAppContainerOffset", identifier, () => {
					return 100;
				});

				const script = document.createElement("script");

				script.src = `https://resc.deskline.net/DW5/start/KAISERWINK/${identifier}/index.js`;
				script.async = true;
				document.body.appendChild(script);
			}
		}, 100);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifier, lang, loaded]);

	return (
		<Container className={css.package} maxWidth="none" noPadding>
			<div id="tosc5target-shop"></div>
		</Container>
	);
};

export default Package;
