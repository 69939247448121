import React from "react";
import loadable from "@loadable/component";

import styles from "./Event.styles";

const Container = loadable(() => import("@Components/views/Container"));
const Detail = loadable(() => import("@micado-digital/react-tourism-event/views/Detail02"));

const Event = () => {
	const css = styles();

	return (
		<Container className={css.event}>
			<Detail
				galleryMediaFormats={{ xs: "event-gallery" }}
				teaserMediaFormats={{
					xs: "event-teaser",
					sm: "event-teaser-small"
				}}
				thumbsMediaFormats={{ xs: "event-gallery-thumbs" }}
			/>
		</Container>
	);
};

export default Event;
