import loadable from "@loadable/component";
import clsx from "clsx";

const BloggerDescription = loadable(() =>
	import("@micado-digital/react-blog/BloggerDescription")
);
const BlogList = loadable(() => import("@micado-digital/react-blog/views/List01"));
const BlogShortInfo = loadable(() => import("@micado-digital/react-blog/BlogShortInfo"));
const BlogTags = loadable(() => import("@micado-digital/react-blog/ListTags"));
const BlogTeaserGroup = loadable(() => import("@micado-digital/react-blog/BlogTeaserGroup"));
const Bookmarks = loadable(() => import("@Components/Elements/Bookmarks"));
const BrochureList = loadable(() =>
	import("@micado-digital/react-tourism-brochure/views/List01")
);
const Button = loadable(() => import("@material-ui/core/Button"));
const Cart = loadable(() => import("@Components/Elements/Cart"));
const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const Container = loadable(() => import("@Components/views/Container"));
const Download = loadable(() => import("./Download"));
const EventList = loadable(() => import("@micado-digital/react-tourism-event/views/List01"));
const Form = loadable(() => import("@micado-digital/react-form"));
const Gallery = loadable(() => import("@Components/Elements/Gallery"));
const IFrame = loadable(() => import("@Components/Elements/IFrame"));
const ImageText = loadable(() => import("@Components/Elements/ImageText"));
const InfrastructureList = loadable(() =>
	import("@micado-digital/react-tourism-infrastructure/views/List01")
);
const BlogComments = loadable(() => import("@micado-digital/react-blog/BlogComments"));
const ListBlogger = loadable(() => import("@micado-digital/react-blog/ListBlogger"));
const ProviderList = loadable(() =>
	import("@micado-digital/react-tourism-provider/views/List01")
);
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactEBookTeaser01 = loadable(() =>
	import("@micado-digital/react-ebook-teaser/ReactEBookTeaser01")
);
const ReactFacilities02 = loadable(() =>
	import("@micado-digital/react-facilities/ReactFacilities02")
);
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactGallery03 = loadable(() => import("@micado-digital/react-gallery/ReactGallery03"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactHTML02 = loadable(() => import("@micado-digital/react-html/ReactHTML02"));
const ReactImageText03 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText03")
);
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactReferences01 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences01")
);
const ReactReferences03 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences03")
);
const ReactShare = loadable(() => import("@micado-digital/react-share/ReactShare"));
const ReactSingleImage01 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage01")
);
const ReactSingleImage03 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage03")
);
const ReactSingleTeaser03 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser03")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactLegacy = loadable(() => import("@micado-digital/react-legacy/ReactLegacy"));
const ServiceList = loadable(() =>
	import("@micado-digital/react-tourism-service/views/List01")
);
const TourList = loadable(() => import("@micado-digital/react-tourism-tour/views/List01"));
const ReactLiftState02 = loadable(() =>
	import("@micado-digital/react-liftstate/ReactLiftState02")
);
const ReactPistState02 = loadable(() =>
	import("@micado-digital/react-piststate/ReactPistState02")
);
const ReactRates01 = loadable(() => import("@micado-digital/react-rates/ReactRates01"));
const ReactSearchResults = loadable(() =>
	import("@micado-digital/react-search-results/ReactSearchResults")
);
const ReactSnowReport01 = loadable(() =>
	import("@micado-digital/react-snowreport/ReactSnowReport01")
);
const ReactTourismRequest = loadable(() =>
	import("@micado-digital/react-tourism-request/ReactTourismRequest")
);
const ReactWeather07 = loadable(() => import("@micado-digital/react-weather/ReactWeather07"));
const ReactWebcams01 = loadable(() => import("@micado-digital/react-webcams/ReactWebcams01"));
const TeaserGrid = loadable(() => import("@Components/Elements/TeaserGroup/Grid"));
const TeaserSlider = loadable(() => import("@Components/Elements/TeaserGroup/Slider"));
const TourismEventTeaserGrid = loadable(() =>
	import("@Components/Elements/TourismEventTeaserGrid")
);
const TourismEventTeaserList = loadable(() =>
	import("@Components/Elements/TourismEventTeaserList")
);
const TourismEventTeaserSlider = loadable(() =>
	import("@Components/Elements/TourismEventTeaserSlider")
);
const TourismInfrastructureTeaserList = loadable(() =>
	import("@Components/Elements/TourismInfrastructureTeaserList")
);
const TourismInfrastructureTeaserSlider = loadable(() =>
	import("@Components/Elements/TourismInfrastructureTeaserSlider")
);
const TourismPackageTeaserSlider = loadable(() =>
	import("@Components/Elements/TourismPackageTeaserSlider")
);
const TourismProviderTeaserList = loadable(() =>
	import("@Components/Elements/TourismProviderTeaserList")
);
const TourismProviderTeaserSlider = loadable(() =>
	import("@Components/Elements/TourismProviderTeaserSlider")
);
const TourismTourTeaserList = loadable(() =>
	import("@Components/Elements/TourismTourTeaserList")
);
const TourismTourTeaserSlider = loadable(() =>
	import("@Components/Elements/TourismTourTeaserSlider")
);
const Employee = loadable(() => import("@Components/Elements/Employee"));
const Panorama = loadable(() => import("@Components/Elements/Panorama"));
const VirtualSpotIFrame = loadable(() => import("@Components/Elements/VirtualSpot/IFrame"));
const VirtualSpotScript = loadable(() => import("@Components/Elements/VirtualSpot/Script"));
const SummitlynxCheckins = loadable(() => import("@Components/Elements/SummitlynxCheckins"));

const getJSXElement = (element, lang, l, isMobile, sysLink, blogData, layoutVariant) => {
	const { REACT_APP_PATH } = process.env;
	const { anchor, id, tag } = element;

	switch (tag) {
		case "basic-headline": {
			const { text, variant = 0 } = element;

			let config = {};

			switch (variant) {
				case 0: {
					config = {
						component: "h1",
						variant: "display-large"
					};
					break;
				}
				case 1: {
					config = {
						component: "h2",
						variant: "display-small"
					};
					break;
				}
				case 2: {
					config = {
						component: "h3",
						variants: {
							xs: "headline-medium",
							md: "headline-large"
						}
					};
					break;
				}
				case 3: {
					config = {
						component: "h4",
						variants: {
							xs: "headline-small",
							md: "headline-medium"
						}
					};
					break;
				}
				case 4: {
					config = {
						component: "h5",
						variant: "headline-small"
					};
					break;
				}
				case 5: {
					config = {
						component: "h6",
						variant: "title-large"
					};
					break;
				}
				case 6: {
					config = {
						component: "p",
						variants: {
							xs: "title-small",
							md: "title-medium"
						}
					};
					break;
				}
				default: {
					break;
				}
			}

			return (
				<Container
					anchor={anchor}
					className={clsx(`mco-view-component-container-basic-headline--${variant + 1}`, {
						"mco-view-component-container-basic-headline--summer-nature":
							layoutVariant === "summer-nature"
					})}
					key={id}
					maxWidth={1688}
					tag={tag}
				>
					<ReactHeadline01
						component={config?.component}
						text={text}
						variant={config?.variant}
						variants={config?.variants}
					/>
				</Container>
			);
		}

		case "basic-text": {
			const { text, variant } = element;

			return (
				<Container
					anchor={anchor}
					className={clsx(
						{ "mco-view-component-container-basic-text--2-columns": variant === 1 },
						{
							"mco-view-component-container-basic-text--2-columns-summer-nature":
								layoutVariant === "summer-nature"
						}
					)}
					key={id}
					maxWidth={1136}
					tag={tag}
				>
					<ReactText01 text={text} variant="body-medium" />
				</Container>
			);
		}

		case "basic-list": {
			const { textcontent, title, variant } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
					<ReactList01
						headlineVariant="display-small"
						icon={<div className="mco-list-01__list-item-icon" />}
						textcontent={textcontent}
						textVariant="body-medium"
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-table": {
			const { addition, title, textcontent, variant } = element;

			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactTable01
						addition={addition}
						headlineVariant="display-small"
						items={newTableArray}
						title={title}
						sticky={variant === 1 ? true : false}
					/>
				</Container>
			);
		}

		case "basic-links": {
			const { elements, title, variant = 0 } = element;

			return (
				<Container
					anchor={anchor}
					className={clsx(`mco-view-component-container-basic-links--${variant + 1}`)}
					key={id}
					maxWidth={1136}
					tag={tag}
				>
					{variant === 1 || variant === 2 ? (
						<ReactReferences03
							elements={elements}
							hasIcon={false}
							headlineVariant="display-small"
							linkVariant="button-medium"
							title={title}
						/>
					) : (
						<ReactReferences01
							elements={elements}
							headlineVariant="display-small"
							linkVariant="body-medium"
							title={title}
						/>
					)}
				</Container>
			);
		}

		case "basic-video": {
			const { media, title, text, variant = 0 } = element;

			return (
				<Container
					anchor={anchor}
					className={`mco-view-component-container-basic-video--variant-${variant}`}
					key={id}
					tag={tag}
				>
					<ReactVideo02
						additionVariant="body-small"
						titleVariant="headline-large"
						media={media?.[0]}
						mediaFormats={{
							xs: "video-mobile",
							sm: "video"
						}}
						mediaPath={REACT_APP_PATH}
						showInOverlay={false}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			const { id, textcontent } = element;

			const externalScript = textcontent?.items?.find(
				item => item?.name === "valExternalScript"
			)?.text;
			const html = textcontent?.items?.find(item => item?.name === "valHTML")?.text;
			const script = textcontent?.items?.find(item => item?.name === "valScript")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactHTML02 externalScript={externalScript} html={html} id={id} script={script} />
				</Container>
			);
		}

		case "basic-iframe": {
			const { addition, id, option2, text } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<IFrame height={option2} scrolling={addition} src={text} />
				</Container>
			);
		}

		case "basic-download-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			const { media, title, variant } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
						<Download media={media} title={title} />
					</Container>
				);
			}
			return (
				<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-imprint": {
			return (
				<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
					<ReactLegacy lang={lang} logoIdentifier="logo" />
				</Container>
			);
		}

		case "basic-dropdown-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
					<ReactDropDown01
						icon={<ReactSVGIcon color="primary" src="/img/icons/dropdown.svg" size={24} />}
						option={0}
						title={element?.children?.[0]?.title}
					>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item, lang))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleimage": {
			const { addition, media, option2, reference, title, text, variant } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
						<ReactSingleImage01
							addition={addition}
							media={media ? media?.[0] : {}}
							mediaFormats={{
								xs: "singleimage-small-mobile",
								sm: "singleimage-small"
							}}
							mediaPath={REACT_APP_PATH}
							option2={option2}
							reference={reference}
							text={text}
							textVariant="body-small"
						/>
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth="none" noPadding tag={tag}>
					<ReactSingleImage03
						addition={addition}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "singleimage-mobile",
							sm: "singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						option2={option2}
						reference={reference}
						text={text}
						title={title}
						titleVariants={{
							xs: "headline-large"
						}}
						strength={200}
					/>
				</Container>
			);
		}

		case "basic-teaser": {
			const {
				addition2: externalURL,
				id,
				teasergroup: { items = [] } = {},
				reference: { pageName } = {},
				option4 = 0,
				text,
				title,
				variant = 0
			} = element;

			let link = "";

			if (externalURL) {
				link = externalURL;
			} else if (pageName) {
				link = pageName;
			}

			const target = option4 === 1 ? "_blank" : null;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} maxWidth="none" tag={tag} noPadding>
						<TeaserSlider
							headlineVariants={{
								xs: "display-small",
								lg: "display-large"
							}}
							items={items}
							lang={lang}
							link={link}
							mediaFormats={{
								xs: "teaser"
							}}
							mediaPath={REACT_APP_PATH}
							target={target}
							text={text}
							title={title}
						/>
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth="none" noPadding tag={tag}>
					<TeaserGrid
						headlineVariants={{
							xs: "display-small",
							lg: "display-large"
						}}
						items={items}
						lang={lang}
						link={link}
						mediaFormats={{
							xs: "teaser-grid"
						}}
						mediaPath={REACT_APP_PATH}
						target={target}
						text={text}
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-singleteaser": {
			const {
				id,
				teaser: {
					addition,
					fields = [],
					link,
					media,
					text: teaserText,
					title: teaserTitle
				} = {},
				text,
				title,
				variant = 0
			} = element;

			return (
				<Container
					anchor={anchor}
					className={`mco-view-component-container-basic-singleteaser--${variant + 1}`}
					key={id}
					tag={tag}
				>
					<ReactSingleTeaser03
						buttonColor="primary"
						buttonLabel={
							variant === 2 || variant === 3 || variant === 4 || variant === 5
								? addition ?? l("teaser.button")
								: l("teaser.button")
						}
						buttonVariant="contained"
						headlineVariants={{
							xs: "headline-large",
							md: "display-small"
						}}
						teaser={{
							addition,
							fields,
							id,
							link,
							media,
							text: text ? text : teaserText,
							title: title ? title : teaserTitle
						}}
						lang={lang}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser",
							sm: "teaser-medium"
						}}
						textVariant="body-medium"
						variant={variant === 1 || variant === 3 || variant === 5 ? 1 : 0}
					/>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			const { elements, id, textcontent, variant } = element;

			const addition = textcontent?.items?.find(item => item?.name === "valAddition")?.text;
			const title = textcontent?.items?.find(item => item?.name === "valTitle")?.text;
			const text = textcontent?.items?.find(item => item?.name === "valText")?.text;
			const link = textcontent?.items?.find(item => item?.name === "valUrl")?.text;
			const media = elements?.find(e => e.media)?.media?.[0];

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactSingleTeaser03
						buttonColor="primary"
						buttonLabel={l("teaser.button")}
						buttonVariant="contained"
						headlineVariants={{
							xs: "headline-large",
							md: "display-small"
						}}
						teaser={{
							addition,
							id,
							link,
							linkTarget: "_blank",
							media,
							text,
							title
						}}
						lang={lang}
						media={media}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser",
							sm: "teaser-medium"
						}}
						textVariant="body-medium"
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			const { addition, addition2, media, option, text, title, variant = 0 } = element;

			if (variant === 2) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						<ImageText
							alternativeText={addition}
							media={media}
							option={option}
							text={text}
							title={addition2}
							variant={variant}
						/>
					</Container>
				);
			}

			return (
				<Container
					anchor={anchor}
					className={`mco-view-component-container-basic-imagetext--variant-${variant}`}
					key={id}
					tag={tag}
				>
					<ReactImageText03
						alt={addition}
						addition={title}
						additionVariant="body-small"
						headlineVariants={{
							xs: "headline-large",
							md: "display-small"
						}}
						media={media?.[0]}
						mediaFormats={{
							xs: "imagetext-combi-small",
							sm: "imagetext-combi",
							md: "imagetext-combi-medium"
						}}
						mediaPath={REACT_APP_PATH}
						title={addition2}
						text={text}
						textComponent="div"
						textVariant="body-medium"
						variant={option}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			const { elements, variant = 0 } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						<ReactGallery03
							breakpoints={{
								xs: 1,
								sm: 2,
								md: 3
							}}
							elements={elements}
							lang={lang}
							mediaFormats={{
								xs: "gallery-mobile",
								sm: "gallery-preview"
							}}
							overlayMediaFormats={{
								xs: "gallery"
							}}
							spacing={24}
						/>
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth="none" noPadding tag={tag}>
					<Gallery
						elements={elements}
						lang={lang}
						navNext={
							<ReactSVGIcon color="primary" src="/img/icons/chevron-right.svg" size={32} />
						}
						navPrev={
							<ReactSVGIcon color="primary" src="/img/icons/chevron-left.svg" size={32} />
						}
						mediaFormats={{
							xs: "gallery-mobile",
							sm: "gallery-preview"
						}}
						showPagination={true}
						overlayMediaFormats={{
							xs: "gallery"
						}}
					/>
				</Container>
			);
		}

		case "basic-form": {
			const { elements } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST">
						<Connector formElements={elements} formID={id} />
					</Form>
				</Container>
			);
		}

		case "extension-search-result": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactSearchResults
						api={`${REACT_APP_PATH}/searchv2.json.api`}
						detailUrl={sysLink("search")}
						lang={lang}
						icon={
							<ReactSVGIcon src={REACT_APP_PATH + "/img/icons/chevron-right.svg"} size={24} />
						}
					/>
				</Container>
			);
		}

		case "extension-bookmark": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<Bookmarks lang={lang} />
				</Container>
			);
		}

		case "extension-ebook": {
			const { elements, textcontent: { items } = {} } = element;

			let title = items?.find(e => e.name === "title")?.text;
			let text = items?.find(e => e.name === "text")?.text;
			let media = elements?.find(e => e.media)?.media?.[0];
			let filename = elements?.[0]?.media?.find(e => e.filename)?.filename;
			let lastPathName = filename?.substring(filename.lastIndexOf("/") + 1);
			let download = elements?.[0]?.media?.find(e => e.fileType === "PDF")?.filename;
			let browseURL = lastPathName?.replace(/\.[^/.]+$/, "") + `.html`;

			return (
				<Container anchor={anchor} maxWidth={1204} key={id} tag={tag}>
					<div>
						<ReactEBookTeaser01
							browse={`${REACT_APP_PATH}/ebook/${browseURL}`}
							browseIcon={"/img/icons/book.svg"}
							download={download}
							downloadIcon={"/img/icons/download.svg"}
							lang={lang}
							media={media}
							mediaFormats={{
								xs: "ebook-teaser"
							}}
							text={text}
							title={title}
						/>
					</div>
				</Container>
			);
		}

		case "extension-employee": {
			const { elements } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<Employee employeeData={elements} />
				</Container>
			);
		}

		case "extension-panorama": {
			const { textcontent: { items = [] } = {} } = element;

			const panId = items?.find(({ name }) => name === "valID")?.text;
			const panTitle = items?.find(({ name }) => name === "valTitle")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<Panorama panId={panId} panTitle={panTitle} />
				</Container>
			);
		}

		case "extension-virtual-spot": {
			const { textcontent: { items = [] } = {}, variant } = element;

			const location = items?.find(({ name }) => name === "valLocation")?.text;
			const pitch = items?.find(({ name }) => name === "valPitch")?.text;

			return variant === 1 ? (
				<Container anchor={anchor} key={id} tag={tag}>
					<VirtualSpotIFrame location={location} pitch={pitch} />
				</Container>
			) : (
				<Container anchor={anchor} key={id} tag={tag}>
					<VirtualSpotScript location={location} pitch={pitch} />
				</Container>
			);
		}

		case "extension-summit-location-checkins": {
			const { textcontent: { items = [] } = {}, addition } = element;

			const locationId = items?.find(({ name }) => name === "valRegion")?.text;
			const locationName = addition ? addition.slice(1, -1) : "";

			const apiUrl = `${REACT_APP_PATH}/Kaiserwinkl.Support/Kaiserwinkl.Support.Summitlynx.Api.ForApi/GetLocationCheckins.api?LocationId=${locationId}`;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<SummitlynxCheckins apiUrl={apiUrl} lang={lang} locationName={locationName} />
				</Container>
			);
		}

		case "extension-summit-region-checkins": {
			const apiUrl = `${REACT_APP_PATH}/Kaiserwinkl.Support/Kaiserwinkl.Support.Summitlynx.Api.ForApi/GetRegionCheckins.api?RegionId=19`;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<SummitlynxCheckins apiUrl={apiUrl} lang={lang} />
				</Container>
			);
		}

		case "tourism-cart": {
			const { textcontent: { items = [] } = {} } = element;

			const cartLinks = {
				booking: sysLink("accommodations"),
				privacy: sysLink("privacy"),
				productOverview: sysLink("accommodations"),
				redirectEmpty: sysLink("tourism-cart-empty"),
				redirectError: sysLink("tourism-cart-error"),
				redirectSuccess: sysLink("tourism-cart-success"),
				terms: sysLink("terms")
			};

			const version = items?.find(({ name }) => name === "valMode")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<Cart
						lang={lang}
						mediaFormats={{
							xs: "product-teaser",
							sm: "product-teaser-small"
						}}
						systemLinks={cartLinks}
						version={version === "widget" ? 2 : 1}
						widgetIdentifier="bf480092-3144-4e48-b38e-72d5c0f10325"
						widgetLocalStorageID="lechtal"
					/>
				</Container>
			);
		}

		case "tourism-events-teaser": {
			const { elements = [], textcontent: { items = [] } = {}, variant } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						<TourismEventTeaserList elements={elements} items={items} />
					</Container>
				);
			}

			if (variant === 2) {
				return (
					<Container anchor={anchor} key={id} maxWidth="none" noPadding tag={tag}>
						<TourismEventTeaserGrid elements={elements} items={items} />
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth="none" tag={tag}>
					<TourismEventTeaserSlider elements={elements} items={items} />
				</Container>
			);
		}

		case "tourism-infrastructures-teaser": {
			const { elements = [], textcontent: { items = [] } = {}, variant } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						<TourismInfrastructureTeaserList elements={elements} items={items} />
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth="none" tag={tag}>
					<TourismInfrastructureTeaserSlider elements={elements} items={items} />
				</Container>
			);
		}

		case "tourism-packages-teaser": {
			const { elements = [], textcontent: { items = [] } = {} } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth="none" tag={tag}>
					<TourismPackageTeaserSlider elements={elements} items={items} />
				</Container>
			);
		}

		case "tourism-providers-teaser": {
			const { elements = [], textcontent: { items = [] } = {}, variant } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						<TourismProviderTeaserList elements={elements} items={items} />
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth="none" tag={tag}>
					<TourismProviderTeaserSlider elements={elements} items={items} />
				</Container>
			);
		}

		case "tourism-tours-teaser": {
			const { elements = [], textcontent: { items = [] } = {}, variant } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						<TourismTourTeaserList elements={elements} items={items} />
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth="none" tag={tag}>
					<TourismTourTeaserSlider elements={elements} items={items} />
				</Container>
			);
		}

		case "tourism-events-list": {
			const { textcontent: { items = [] } = {} } = element;

			const listMode = items?.find(({ name }) => name === "listMode")?.text;
			const showFilter = items?.find(({ name }) => name === "showFilter")?.text === "True";
			const top = items?.find(({ name }) => name === "top")?.text === "True";

			let categories = items?.find(({ name }) => name === "categories")?.text;
			let holidayThemes = items?.find(({ name }) => name === "holidayThemes")?.text;
			let selectedFilter = items?.find(({ name }) => name === "selectedFilter")?.text;
			let towns = items?.find(({ name }) => name === "towns")?.text;

			if (categories) categories = categories?.split("|")?.map(category => parseInt(category));
			if (holidayThemes)
				holidayThemes = holidayThemes?.split("|")?.map(holidayTheme => parseInt(holidayTheme));
			if (selectedFilter) selectedFilter = selectedFilter?.split("|");
			if (towns) towns = towns?.split("|")?.map(town => parseInt(town));

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<EventList
						categories={categories}
						holidayThemes={holidayThemes}
						lang={lang}
						listMode={listMode}
						mediaFormats={{
							xs: "event-teaser",
							sm: "event-teaser-small"
						}}
						reportTemplateIdentifier="events" // TODO: Change if settings in website profile are available
						selectedFilter={selectedFilter}
						showFilter={showFilter}
						towns={towns}
						top={top}
					/>
				</Container>
			);
		}

		case "tourism-infrastructures-list": {
			const { textcontent: { items = [] } = {} } = element;

			const listMode = items?.find(({ name }) => name === "listMode")?.text;
			const mapURL = items?.find(({ name }) => name === "mapURL")?.text;
			const showDate = items?.find(({ name }) => name === "showDate")?.text === "True";
			const showFilter = items?.find(({ name }) => name === "showFilter")?.text === "True";

			let categories = items?.find(({ name }) => name === "categories")?.text;
			let selectedFilter = items?.find(({ name }) => name === "selectedFilter")?.text;
			let sort = items?.find(({ name }) => name === "sort")?.text;
			let towns = items?.find(({ name }) => name === "towns")?.text;

			if (categories) categories = categories?.split("|")?.map(category => parseInt(category));
			if (selectedFilter) selectedFilter = selectedFilter?.split("|");
			if (towns) towns = towns?.split("|")?.map(town => parseInt(town));

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<InfrastructureList
						categories={categories}
						externalMapURL={mapURL}
						hideMapIcon={false}
						lang={lang}
						listMode={listMode}
						mediaFormats={{
							xs: "infrastructure-teaser",
							sm: "infrastructure-teaser-small"
						}}
						selectedFilter={selectedFilter}
						showDate={showDate}
						showFilter={showFilter}
						sort={sort}
						towns={towns}
					/>
				</Container>
			);
		}

		case "tourism-providers-list": {
			const { textcontent: { items = [] } = {} } = element;

			const listMode = items?.find(({ name }) => name === "listMode")?.text;

			let attributes = items?.find(({ name }) => name === "attributes")?.text;
			let bookable = items?.find(({ name }) => name === "bookable")?.text;
			let categories = items?.find(({ name }) => name === "categories")?.text;
			let classifications = items?.find(({ name }) => name === "classifications")?.text;
			let marketingGroups = items?.find(({ name }) => name === "marketingGroups")?.text;
			let selectedFilter = items?.find(({ name }) => name === "selectedFilter")?.text;
			let sort = items?.find(({ name }) => name === "sort")?.text;
			let towns = items?.find(({ name }) => name === "towns")?.text;

			if (attributes)
				attributes = attributes?.split("|")?.map(attribute => parseInt(attribute));
			if (categories) categories = categories?.split("|")?.map(category => parseInt(category));
			if (classifications)
				classifications = classifications
					?.split("|")
					?.map(classification => parseInt(classification));
			if (marketingGroups)
				marketingGroups = marketingGroups
					?.split("|")
					?.map(marketinggroup => parseInt(marketinggroup));
			if (selectedFilter) selectedFilter = selectedFilter?.split("|");
			if (sort) sort = sort?.split("|")?.join(",");
			if (towns) towns = towns?.split("|")?.map(town => parseInt(town));

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ProviderList
						attributes={attributes}
						bookable={bookable}
						categories={categories}
						classifications={classifications}
						key={id}
						lang={lang}
						listMode={listMode}
						mapStartPosition={{
							lat: 47.2870781,
							lng: 10.4304017
						}}
						mapZoom={16}
						marketingGroups={marketingGroups}
						mediaFormats={{
							xs: "provider-teaser",
							sm: "provider-teaser-small"
						}}
						selectedFilter={selectedFilter}
						showAttributes
						showMarketingGroups
						sort={sort}
						towns={towns}
					/>
				</Container>
			);
		}

		case "tourism-services-list": {
			const { textcontent: { items = [] } = {} } = element;

			const listMode = items?.find(({ name }) => name === "listMode")?.text;

			let categories = items?.find(({ name }) => name === "categories")?.text;
			let holidayThemes = items?.find(({ name }) => name === "holidayThemes")?.text;
			let selectedFilter = items?.find(({ name }) => name === "selectedFilter")?.text;
			let towns = items?.find(({ name }) => name === "towns")?.text;

			if (categories) categories = categories?.split("|")?.map(category => parseInt(category));
			if (holidayThemes)
				holidayThemes = holidayThemes?.split("|")?.map(holidayTheme => parseInt(holidayTheme));
			if (selectedFilter) selectedFilter = selectedFilter?.split("|");
			if (towns) towns = towns?.split("|")?.map(town => parseInt(town));

			return (
				<Container anchor={anchor} indent key={id} tag={tag}>
					<ServiceList
						categories={categories}
						holidayThemes={holidayThemes}
						lang={lang}
						listMode={listMode}
						mediaFormats={{
							xs: "product-teaser",
							sm: "product-teaser-small"
						}}
						selectedFilter={selectedFilter}
						towns={towns}
					/>
				</Container>
			);
		}

		case "tourism-tours-list": {
			const { textcontent: { items = [] } = {} } = element;

			let attributes = items?.find(({ name }) => name === "attributes")?.text;
			let categories = items?.find(({ name }) => name === "categories")?.text;
			let difficulties = items?.find(({ name }) => name === "difficulties")?.text;
			let selectedFilter = items?.find(({ name }) => name === "selectedFilter")?.text;

			const listMode = items?.find(({ name }) => name === "listMode")?.text;
			const mapURL = items?.find(({ name }) => name === "mapURL")?.text;
			const showAltitude = items?.find(({ name }) => name === "showAltitude")?.text === "True";
			const showDistance = items?.find(({ name }) => name === "showDistance")?.text === "True";
			const showDuration = items?.find(({ name }) => name === "showDuration")?.text === "True";
			const showFilter = items?.find(({ name }) => name === "showFilter")?.text === "True";
			const sort = items?.find(({ name }) => name === "sort")?.text;

			if (attributes)
				attributes = attributes?.split("|")?.map(attribute => parseInt(attribute));
			if (categories) categories = categories?.split("|")?.map(category => parseInt(category));
			if (difficulties)
				difficulties = difficulties?.split("|")?.map(difficulty => parseInt(difficulty));
			if (selectedFilter) selectedFilter = selectedFilter?.split("|");

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<TourList
						attributes={attributes}
						categories={categories}
						difficulties={difficulties}
						externalMapURL={mapURL}
						hideMapIcon={false}
						lang={lang}
						listMode={listMode}
						mediaFormats={{
							xs: "tour-teaser",
							sm: "tour-teaser-small"
						}}
						selectedFilter={selectedFilter}
						showAltitude={showAltitude}
						showDistance={showDistance}
						showDuration={showDuration}
						showFilter={showFilter}
						sort={sort}
					/>
				</Container>
			);
		}

		case "tourism-brochures-order": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BrochureList lang={lang} mediaFormats={{ xs: "brochure-teaser" }} />
				</Container>
			);
		}

		case "tourism-request-pool": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactTourismRequest
						classes={{ form: "mco-form" }}
						formURL={`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.ProviderApi/SendOrganisationRequest.api`}
						lang={lang}
						redirect={sysLink("tourism-request-success")}
						systemLinks={{
							privacy: sysLink("privacy")
						}}
						type="organisation"
					/>
				</Container>
			);
		}

		case "mski-liftstate": {
			const { textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "valClient")?.text;
			const region = items?.find(({ name }) => name === "valRegion")?.text;
			const season = items?.find(({ name }) => name === "valSeason")?.text;
			const location = items?.find(({ name }) => name === "valLocation")?.text;
			const selected = items?.find(({ name }) => name === "valSelected")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactLiftState02
						client={client}
						defaultFilter="open"
						filter
						lang={lang}
						location={location}
						region={region}
						season={season}
						selected={selected}
						showOpenedFirst
						skeletonItems={20}
						stateType="text"
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
					/>
				</Container>
			);
		}

		case "mski-piststate": {
			const { textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "valClient")?.text;
			const region = items?.find(({ name }) => name === "valRegion")?.text;
			const season = items?.find(({ name }) => name === "valSeason")?.text;
			const location = items?.find(({ name }) => name === "valLocation")?.text;
			const selected = items?.find(({ name }) => name === "valSelected")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactPistState02
						client={client}
						defaultFilter="open"
						filter
						lang={lang}
						location={location}
						region={region}
						season={season}
						selected={selected}
						showOpenedFirst
						skeletonItems={20}
						stateType="text"
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
					/>
				</Container>
			);
		}

		case "mski-facilities": {
			const { textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "valClient")?.text;
			const region = items?.find(({ name }) => name === "valRegion")?.text;
			const season = items?.find(({ name }) => name === "valSeason")?.text;
			const location = items?.find(({ name }) => name === "valLocation")?.text;
			const type = items?.find(({ name }) => name === "valType")?.text;
			const selected = items?.find(({ name }) => name === "valSelected")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactFacilities02
						client={client}
						defaultFilter="all"
						filter
						lang={lang}
						location={location}
						region={region}
						season={season}
						selected={selected}
						showOpenedFirst
						skeletonItems={20}
						stateType="text"
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
						type={type}
					/>
				</Container>
			);
		}

		case "mski-webcams": {
			const { textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "valClient")?.text;
			const region = items?.find(({ name }) => name === "valRegion")?.text;
			const selected = items?.find(({ name }) => name === "valSelected")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactWebcams01
						client={client}
						fallbackImage="/img/fallback-webcam.jpg"
						gridBreakpoints={{
							xs: 12,
							sm: 6,
							md: 4
						}}
						lang={lang}
						live={true}
						overlaySize="xlarge"
						region={region}
						selected={selected}
						showLocation
						skeletonItems={12}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WebcamApi/ListWebcams.api`}
					/>
				</Container>
			);
		}

		case "mski-weather": {
			const { textcontent: { items = [] } = {} } = element;

			const station = items?.find(({ name }) => name === "valStation")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactWeather07
						client="https://sgm.kaiserwinkl.com"
						days="0|1|2|3"
						lang={lang}
						region="kaiserwinkl"
						stations={parseInt(station)}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WeatherApi/GetDetails.api`}
					/>
				</Container>
			);
		}

		case "mski-snowreport": {
			const { textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "valClient")?.text;
			const region = items?.find(({ name }) => name === "valRegion")?.text;
			const location = items?.find(({ name }) => name === "valLocation")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactSnowReport01
						client={client}
						iconSize={32}
						identifierAvalanche="avalance-level"
						identifierDateLastSnowfall="date-last-snowfall"
						identifierSnowHeightValley="snow-height-valley"
						identifierSnowHeightMountain="snow-height-mountain"
						lang={lang}
						location={location}
						region={region}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.PropertyApi/ListProperties.api`}
					/>
				</Container>
			);
		}

		case "mski-rates": {
			const { textcontent: { items = [] } = {}, variant } = element;

			const client = items?.find(({ name }) => name === "valClient")?.text;
			const region = items?.find(({ name }) => name === "valRegion")?.text;
			const identifier = items?.find(({ name }) => name === "valRates")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactRates01
						client={client}
						lang={lang}
						rate={identifier}
						region={region}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.RateApi/GetRateTableJSON.api`}
						sticky={variant === 1 ? true : false}
					/>
				</Container>
			);
		}

		case "mco-blog-list": {
			if (!element?.textcontent) {
				return;
			}

			const { textcontent: { items = [] } = {} } = element;

			let categories = items?.find(({ name }) => name === "valCategories")?.text;
			let keywords = items?.find(({ name }) => name === "valKeywords")?.text;
			let author = items?.find(({ name }) => name === "valAuthor")?.text;
			let sort = items?.find(({ name }) => name === "valSort")?.text;

			if (categories) categories = categories?.replaceAll("|", ",");
			if (keywords) keywords = keywords?.replaceAll("|", ",");

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogList
						authorID={author}
						categories={categories}
						keywords={keywords}
						lang={lang}
						mediaFormats={{
							xs: "teaser"
						}}
						view="grid"
						sort={sort}
						PaginationProps={{ shape: "rounded", size: "large" }}
					/>
				</Container>
			);
		}

		case "mco-blog-teaser": {
			if (!element?.textcontent) {
				return;
			}

			const { textcontent: { items = [] } = {}, elements } = element;

			let categories = items?.find(({ name }) => name === "valCategories")?.text;
			let keywords = items?.find(({ name }) => name === "valKeywords")?.text;
			let author = items?.find(({ name }) => name === "valAuthor")?.text;
			let selected = items?.find(({ name }) => name === "valSelected")?.text;
			let headline = items?.find(({ name }) => name === "valHeadline")?.text;
			let url = elements?.find(item => item?.tag === "valURL")?.reference?.pageName;
			let buttonLabel = items?.find(({ name }) => name === "valButton")?.text;
			let pagesize = items?.find(({ name }) => name === "valPageSize")?.text ?? 9;
			let sort = items?.find(({ name }) => name === "valSort")?.text ?? 0;

			if (categories) categories = categories?.replaceAll("|", ",");
			if (keywords) keywords = keywords?.replaceAll("|", ",");
			if (selected) selected = selected?.replaceAll("|", ",");

			const params = {
				authorID: author,
				categories: categories,
				excluded: blogData?.id,
				keywords: keywords,
				selected: selected,
				pageSize: pagesize,
				sort: sort
			};

			const detailButton = (
				<Button color="primary" size="medium" variant="contained">
					{buttonLabel ? buttonLabel : l("blog.buttonAll")}
				</Button>
			);

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogTeaserGroup
						detailButton={detailButton}
						detailURL={url}
						headline={headline}
						headlineVariant="headline-large"
						lang={lang}
						mediaFormats={{
							xs: "teaser"
						}}
						id={1}
						params={params}
						SwiperProps={{
							breakpoints: {
								0: {
									slidesPerView: 1,
									spaceBetween: 16
								},
								600: {
									slidesPerView: 1.2,
									spaceBetween: 16
								},
								800: {
									slidesPerView: 2.1,
									spaceBetween: 16
								},
								960: {
									slidesPerView: 2.2,
									spaceBetween: 16
								},
								1024: {
									slidesPerView: 2.5,
									spaceBetween: 16
								},
								1280: {
									slidesPerView: 3,
									spaceBetween: 20
								}
							},
							delay: 3000,
							speed: 800
						}}
					/>
				</Container>
			);
		}

		case "mco-blog-detail-info": {
			const date = blogData?.editDate ? new Date(blogData?.editDate) : null;
			const dateOptions = { day: "2-digit", month: "long", year: "numeric" };
			const dateStr = date?.toLocaleDateString(undefined, dateOptions);

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogShortInfo authorID={blogData?.authorID} blogDate={dateStr} lang={lang} />
					<ReactShare
						buttonProps={{
							icon: <ReactSVGIcon src="/img/icons/share.svg" size={20} />,
							size: "small"
						}}
						customIcons={true}
						iconSize={24}
						lang={lang}
						shareOptions={{
							email: true,
							facebook: true,
							linkedIn: true,
							pinterest: true,
							twitter: true,
							whatsapp: true
						}}
					/>
				</Container>
			);
		}

		case "mco-blog-list-blogger": {
			const { textcontent: { items = [] } = {}, variant } = element;

			let headline = items?.find(({ name }) => name === "valHeadline")?.text;
			let selected = items?.find(({ name }) => name === "valSelected")?.text;

			if (selected) selected = selected?.replaceAll("|", ",");

			return (
				<Container
					anchor={anchor}
					key={id}
					tag={tag}
					variant={variant === 1 ? "background" : ""}
				>
					<ListBlogger
						headline={headline}
						lang={lang}
						headlineVariant="headline-large"
						selected={selected}
					/>
				</Container>
			);
		}

		case "mco-blog-blogger-desc": {
			return (
				<Container anchor={anchor} key={id} maxWidth={1136} tag={tag}>
					<BloggerDescription
						authorID={blogData?.authorID}
						titleVariant="title-medium"
						lang={lang}
						variant="compact"
					/>
				</Container>
			);
		}

		case "mco-blog-share": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactShare
						buttonProps={{
							icon: <ReactSVGIcon src="/img/icons/share.svg" size={20} />,
							size: "small"
						}}
						customIcons={true}
						iconSize={24}
						lang={lang}
						shareOptions={{
							email: true,
							facebook: true,
							linkedIn: true,
							pinterest: true,
							twitter: true,
							whatsapp: true
						}}
					/>
				</Container>
			);
		}

		case "mco-blog-tags": {
			const { textcontent: { items = [] } = {} } = element;

			let headline = items?.find(({ name }) => name === "valHeadline")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogTags
						headline={headline}
						headlineVariant="label-small"
						labelVariant="label-small"
					/>
				</Container>
			);
		}

		case "mco-blog-comments": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogComments lang={lang} />
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
