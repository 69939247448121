import React from "react";
import clsx from "clsx";

import styles from "./Container.styles";

const Container = props => {
	const {
		anchor,
		background,
		children,
		className,
		maxWidth: _maxWidth,
		noPadding = false,
		style,
		tag
	} = props;
	const { tag: anchorTag } = anchor || {};
	const maxWidth = _maxWidth || (noPadding ? 1640 : 1688);
	const css = styles(background, maxWidth || 1688, noPadding);

	return (
		<div
			className={clsx(
				css.container,
				"mco-view-component-container",
				className,
				tag ? `mco-view-component-container-${tag}` : ""
			)}
			data-anchor={anchorTag}
			style={style}
		>
			{children}
		</div>
	);
};

export default Container;
