import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	page: {
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		overflow: "clip",
		paddingTop: 80,
		[theme.breakpoints.up("sm")]: {
			paddingTop: 99
		},
		[theme.breakpoints.up("md")]: {
			paddingTop: 150
		}
	},
	pageIntro: {
		paddingTop: 0
	}
}));

export default styles;
